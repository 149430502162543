import axios from 'axios'
import { useEffect, useState } from 'react'
import { CloseIcon, PlatformIcons } from '../../../assets/Icons'
import { useNavigate } from 'react-router-dom'
import IOSInstruction1URL from '../../../assets/IOSInstruction1.png'
import IOSInstruction2URL from '../../../assets/IOSInstruction2.png'
import IOSInstruction2_2URL from '../../../assets/IOSInstruction2_2.png'
import IOSInstruction3URL from '../../../assets/IOSInstruction3.png'

const TextBanner = ({ bgColor = '#FE902A', text = 'Coming Soon' }) => {
  return (
    <div className="absolute top-0 -translate-y-1/2 right-2 w-fit">
      <div
        className={`px-1 py-[2px] text-white text-[12px] font-bold uppercase bg-[${bgColor}] rounded-[2px]`}
      >
        {text}
      </div>
    </div>
  )
}

type OS = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux'

const getOS = () => {
  const userAgent = window.navigator.userAgent,
    platform =
      // @ts-ignore
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os: OS | null = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

let defferPromptEvent: any = null

export const PlaySection = () => {
  const [isIOSModalOpen, setIsIOSModalOpen] = useState(false)
  const [os, setOS] = useState<OS>('Windows')

  const navigate = useNavigate()

  useEffect(() => {
    const _os = getOS()
    setOS(_os || 'Windows')

    const handleBeforeInstallPrompt = (e: any) => {
      defferPromptEvent = e
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
    }
  }, [])

  const getGameBuilds = async (platform: string) => {
    try {
      const kapUlr = 'https://api.kap.gg/api/games/97/builds/'
      const response = await axios.get(kapUlr)

      const { results } = response.data

      results.find((res: any) => {
        if (res.platform === platform) {
          window.open(res.build, '_blank')
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleBrowserPlayClick = async () => {
    if (os === 'Windows' || os === 'Mac OS' || os === 'Linux') {
      window.open('https://www.kap.gg/app/capncompany?fullscreen=true')
      return
    }

    if (os === 'iOS') {
      setIsIOSModalOpen(true)
      return
    }

    // Android
    if (defferPromptEvent && defferPromptEvent.prompt) {
      defferPromptEvent.prompt()
      defferPromptEvent.userChoice.then((choiceResult: any) => {
        if (choiceResult?.outcome === 'accepted') {
          console.log('User chose to install the app')
          navigate('/play')
        } else {
          console.log('User dismissed the install prompt')
        }
      })
    }
  }

  const handleDownloadClick = async () => {
    if (os === 'Windows') {
      await getGameBuilds('windows')
      return
    }
    if (os === 'Mac OS') {
      await getGameBuilds('mac')
      return
    }
    if (os === 'Android') {
      await getGameBuilds('android')
      return
    }
  }

  return (
    <div id="playSection" className="w-full ">
      <div className="flex max-xl:flex-col max-xl:items-center justify-center gap-[60px] max-lg:gap-10">
        <div className="flex flex-col text-white -rotate-2 max-lg:rotate-0 max-lg:text-center">
          <div className="text-5xl font-bold">Play Now!</div>
          <div className="text-xl font-medium">On any platform</div>
        </div>

        <div className="flex max-xl:flex-col items-center gap-5">
          <div className="flex max-sm:flex-col max-sm:items-center gap-5">
            <div className="flex gap-5">
              <div
                className="relative cursor-pointer"
                onClick={handleBrowserPlayClick}
              >
                <PlatformIcons.Browser />
              </div>
              {os !== 'Linux' && os !== 'iOS' && (
                <div
                  className="relative cursor-pointer"
                  onClick={handleDownloadClick}
                >
                  <PlatformIcons.Download />
                </div>
              )}
            </div>
            <div className="flex gap-5">
              <div
                className="relative cursor-pointer"
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.kapgames.captainandcompany',
                    '_blank'
                  )
                }
              >
                <PlatformIcons.GooglePlay />
                <TextBanner text="Wishlist" />
              </div>
              <div className="relative">
                <PlatformIcons.AppStore />
                <TextBanner text="Coming Soon" />
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="relative">
              <PlatformIcons.Steam />
              <TextBanner text="Coming Soon" />
            </div>
            <div className="relative">
              <PlatformIcons.Epic />
              <TextBanner text="Coming Soon" />
            </div>
          </div>
        </div>
      </div>

      {isIOSModalOpen && (
        <div className="z-50 fixed inset-0 size-full bg-[#0A101B]/90 backdrop-blur-lg">
          <div className="h-full flex flex-col">
            <div className="py-6 px-[30px] flex justify-between items-center">
              <div className="grow"></div>
              <div
                className="cursor-pointer"
                onClick={() => setIsIOSModalOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="grow p-6 flex flex-col gap-9 items-center text-white overflow-y-scroll [&::-webkit-scrollbar]:hidden">
              <div className="text-xl font-medium">
                How to install C&C on Your iPhone
              </div>
              <div className="flex flex-col gap-6 items-center">
                <div>Click on middle icon</div>
                <div className="relative w-[298px] h-[102px]">
                  <img src={IOSInstruction1URL} className="rounded-lg" alt="" />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      1
                    </div>
                  </div>
                  <div className="absolute size-[93px] top-[32px] left-1/2 -translate-x-1/2 rounded-full border border-white/20 overflow-hidden bg-black">
                    <img
                      src={IOSInstruction1URL}
                      className="w-[401.65px] h-[137.48px] object-cover -translate-y-[37px]"
                      alt=""
                    />
                  </div>
                </div>
                <div>Find the Add to home screen option</div>
                <div className="relative w-[298px] h-[120px]">
                  <img
                    src={IOSInstruction2URL}
                    className="rounded-lg size-full"
                    alt=""
                  />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      2
                    </div>
                  </div>
                  <div className="absolute size-[150px] top-[6px] left-[190px] rounded-full 0border 0border-white/20 0bg-black">
                    <img
                      src={IOSInstruction2_2URL}
                      className="size-full"
                      alt=""
                    />
                  </div>
                </div>
                <div>Enjoy the game from your home screen</div>
                <div className="relative w-[298px] h-[102px]">
                  <img src={IOSInstruction3URL} className="rounded-lg" alt="" />
                  <div className="absolute -inset-[15px]">
                    <div className="size-[30px] flex items-center justify-center bg-[#FE902A] border-2 border-white rounded-full font-bold">
                      3
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
