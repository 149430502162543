import { useNavigate } from 'react-router-dom'
import CapncoLogoURL from '../assets/CapncoLogo.png'
import {
  DiscordIcon,
  FooterTopPatter,
  PlayIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets/Icons'
import KapLogoURL from '../assets/KapLogo.png'
import { SolidButton } from './components'
import { discordUrl, twitchUrl, twitterUrl, youtubeUrl } from './links'

export const Footer = () => {
  const navigate = useNavigate()

  const openLink = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div className="w-full">
      <FooterTopPatter />
      <div className=" -mt-20 pt-14 flex flex-col items-center bg-black">
        <div className="max-sm:px-6 pt-6 flex flex-col gap-9 items-center">
          <div className="text-white font-bold text-5xl text-center">
            JOIN OUR COMMUNITY
          </div>
          <div className="max-sm:w-full flex max-sm:flex-col gap-[34px]">
            <SolidButton
              className="max-sm:w-full max-sm:flex max-sm:justify-center"
              onClick={() => openLink(discordUrl)}
            >
              <div className=" flex items-center gap-[10px]">
                <DiscordIcon />
                Discord
              </div>
            </SolidButton>
            <SolidButton
              className="max-sm:w-full max-sm:flex max-sm:justify-center"
              onClick={() => openLink('https://www.kap.gg/app/capncompany?fullscreen=true')}
            >
              <div className=" flex items-center gap-[10px]">
                <PlayIcon />
                Play Online
              </div>
            </SolidButton>
          </div>

          <div className="py-6 flex flex-col items-center gap-9">
            <div className="flex items-center gap-6">
              <div
                className="size-14 flex items-center justify-center bg-white bg-opacity-5 rounded-lg cursor-pointer"
                onClick={() => openLink(twitterUrl)}
              >
                <TwitterIcon />
              </div>
              <div
                className="size-14 flex items-center justify-center bg-white bg-opacity-5 rounded-lg cursor-pointer"
                onClick={() => openLink(twitchUrl)}
              >
                <TwitchIcon />
              </div>
              <div
                className="size-14 flex items-center justify-center bg-white bg-opacity-5 rounded-lg cursor-pointer"
                onClick={() => openLink(youtubeUrl)}
              >
                <YoutubeIcon />
              </div>
              <div
                className="size-14 flex items-center justify-center bg-white bg-opacity-5 rounded-lg cursor-pointer"
                onClick={() => openLink(discordUrl)}
              >
                <DiscordIcon />
              </div>
            </div>

            <div className="flex flex-col gap-4 items-center">
              <div className="text-white font-bold">
                © 2024 KAP Games. All right reserved.
              </div>
              <div className="text-sm text-center">
                All trademarks, logos, and copyrights are property of their
                respective owners.
                <br /> No part of this website may be reproduced without written
                permission from KAP Games.
              </div>
            </div>

            <div className="flex items-center gap-6">
              <img className="h-6" src={KapLogoURL} alt="" />
              <img className="h-[38px]" src={CapncoLogoURL} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
